import {collection, getDocs} from 'firebase/firestore/lite';
import db from './firebase';

export default async function fetchData() {
    try {
        const voteCountCollection = collection(db, 'vote-count');
        const dailyCountSnapshot = await getDocs(voteCountCollection);
        const docData = dailyCountSnapshot.docs.map(doc => doc.data());
        docData.forEach(doc => {
            doc.time = doc.time.toDate().toString();
        });
        docData.reverse();
        return docData;
    } catch (err) {
        console.log(err);
    }
}
