const getTableColumns = (data) => {
    return [
        {
            Header: 'Date',
            accessor: 'time',
        },
        {
            Header: 'Total',
            accessor: 'allCharityTotal',
        },
        {
            Header: 'OGK',
            accessor: 'ogkTotal',
        },
        {
            Header: 'OPV',
            accessor: 'opvTotal',
        },
        ...getCharityColumns(data),
    ];
}

function getCharityColumns(data) {
    if (data.length === 0) return [];

    const firstItem = data[0];
    const charityNames = Object.keys(firstItem.charitySummary);

    return charityNames.map(name => ({
        Header: name,
        accessor: `charitySummary.${name}`,
    }));
}

export default getTableColumns;

