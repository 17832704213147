import React, {useEffect, useState} from "react";
import fetchData from './fetchData';
import getTableColumns from './TableColumns';
import Table from './Table';

function App() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData().then((docData) => setData(docData));
    }, []);

    const columns = getTableColumns(data);

    return <Table columns={columns} data={data}/>
}

export default App;
