import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyB83VcBKohn2lN7KOh1afquIEstiV-6nf4",
    authDomain: "one-good-kiwi-35144.firebaseapp.com",
    projectId: "one-good-kiwi-35144",
    storageBucket: "one-good-kiwi-35144.appspot.com",
    messagingSenderId: "911283834002",
    appId: "1:911283834002:web:dc523b4622a5b77b492a4b",
    measurementId: "G-TLBKQ74NG0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
